<template>
  <el-dialog
    :title="titleDialog"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @close="onHandleClose"
    width="40%"
    class="dialog"
    center
  >
    <div class="contents">
      <el-form
        :model="formData"
        ref="refForm"
        :rules="formRules"
        label-width="140px"
      >
        <el-form-item label="添加商品：" prop="sku_code">
          <el-select
            v-model="formData.sku_code"
            placeholder="输入商品名称/货号"
            :loading="loadingSelect"
            :remote-method="onRemoteMethod"
            @visible-change="onChangeVisible"
            clearable
            filterable
            remote
            @change="onChange"
          >
            <el-option
              v-for="item in goodsSelectList"
              :key="item.skuCode"
              :label="`${item.title}【${item.skuCode}】【${
                item?.supplier?.title || ''
              }】`"
              :value="item.skuCode"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="集配中心：" v-if="businessData.name">
          <el-input v-model="businessData.name" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="排序：" prop="sort">
          <el-input v-model="formData.sort" placeholder="输入排序" clearable>
          </el-input>
        </el-form-item>
        <el-form-item label="位置：" prop="source">
          <el-select v-model="formData.source" placeholder="选择位置" clearable>
            <el-option
              v-for="item of sourceList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="置顶天数：">
          <el-input-number
            v-model="formData.days"
            @change="handleChange"
            :min="0"
            label=""
          ></el-input-number>
          <span style="margin-left: 10px">天</span>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="onHandleClose">取消</el-button>
      <el-button type="primary" @click="onHandleSubmit">保存</el-button>
    </span>
  </el-dialog>
</template>

<script>
import cloneDeep from "lodash/cloneDeep";
import { validInteger } from "@/utils/tools/validate";
import { GOODS_SOURCE_ENUM } from "../../utils/enum/index";
import {
  postProductSelectSearch,
  postProductSortListCreate,
} from "@/api/generalControl/config/index";

export default {
  name: "EditDataDialog",
  props: {},
  data() {
    return {
      dialogVisible: false,
      loadingSelect: false,
      titleDialog: "添加商品",
      goodsSelectList: [], // 商品下拉列
      sourceList: Object.values(GOODS_SOURCE_ENUM).filter(
        (item) => item.value !== GOODS_SOURCE_ENUM.ALL.value
      ), // 位置下拉（过滤掉全部）
      formData: {
        sku_code: "", // 商品sku
        sort: "", // 排序
        source: "", // 位置
        days: 0,
      }, // 表单数据
      formDataClone: {}, // 复制一份，用于初始化
      businessData: {}, // 当前商品的集配中心信息
      formRules: {
        sku_code: [
          {
            required: true,
            trigger: ["blur", "change"],
            message: "请输入商品名称/货号",
          },
        ],
        sort: [
          {
            required: true,
            trigger: ["blur", "change"],
            validator: (rule, value, callback) => {
              if (!value || validInteger(value)) {
                callback(new Error("排序为大于0的整数！"));
              }
              callback();
            },
          },
        ],
        source: [
          {
            required: true,
            trigger: ["blur", "change"],
            message: "请选择位置",
          },
        ],
      },
    };
  },
  methods: {
    handleChange(value) {
      console.log(value);
    },
    /**
     * 初始化
     */
    async onInitData() {
      this.dialogVisible = true;
      this.formDataClone = cloneDeep(this.formData);
      this.$refs.refForm?.clearValidate();
    },
    /**
     *获取商品列表
     */
    async ajaxGetProductList(val) {
      try {
        const res = await postProductSelectSearch({
          product_name_or_sku_code: val,
        });
        this.goodsSelectList = res.data;
      } catch (err) {
        console.log("ajax postProductSelectSearch err", err);
      } finally {
        this.loadingSelect = false;
      }
    },
    /**
     * 选中后展示集配中心
     */
    onChange(data) {
      if (!data) {
        this.businessData = {};
        return;
      }
      const find = this.goodsSelectList.find((child) => child.skuCode === data);
      // console.log("🆒 onHandleSelect", this.goodsSelectList, find);
      this.businessData = find.business;
    },
    /**
     *下拉框远程搜索
     */
    onRemoteMethod(query) {
      if (query !== "") {
        this.loadingSelect = true;
        this.ajaxGetProductList(query);
      } else {
        this.goodsSelectList = [];
      }
    },
    /**
     * 下拉框出现/隐藏时触发
     */
    onChangeVisible(e) {
      if (!e) {
        this.goodsSelectList = [];
      }
    },
    /**
     * 关闭操作
     */
    onHandleClose() {
      this.formData = {
        ...this.formDataClone,
      };
      this.businessData = {};
      this.$refs.refForm?.clearValidate();
      this.dialogVisible = false;
    },
    /**
     * 确认
     */
    onHandleSubmit() {
      this.$refs.refForm.validate((valid) => {
        if (!valid) {
          return;
        }
        this.ajaxPostProductSortListCreate();
      });
    },
    /**
     * 提交
     */
    async ajaxPostProductSortListCreate() {
      try {
        const params = {
          ...this.formData,
        };
        params.sort = params.sort;
        await postProductSortListCreate(params);
        this.$message.success("操作成功");
        this.$emit("on-change");
        this.onHandleClose();
      } catch (err) {
        console.log("ajax postProductSortListCreate err", err);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog {
  .contents {
    padding-top: 30px;
    padding-bottom: 20px;
  }
  // /deep/ .el-form {
  //   margin-left: 120px;
  // }
  .el-input,
  .el-select {
    width: 400px;
  }
}
</style>
